//....................................//
// MAin Card Design Extend.......................//
//....................................//

.main-card-ext {
  border: 1px solid;
  border-color: #e8e8e8 #e8e8e8 #dedede;
  border-radius: 2px;
  background: #FFFFFF;
}
//Shadow Only for mobile size
@media (max-width: 480px){
  .main-card-ext {
    border: 0 none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  }
}

//....................................//
// Hide Extra Text ...................//
//....................................//
.hide-extra{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

//....................................//
// No Text Select ...................//
//....................................//
.no-highlight{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

//....................................//
// transition.......................//
//....................................//

.point3-trans{
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.point5-trans{
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.point8-trans{
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

//....................................//
// Shadow.......................//
//....................................//

.box-shadow2{
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
}

//....................................//
// Main Background....................//
//....................................//
$main-bg-color:#F5F5F5;
$main-font-family : 'Roboto', sans-serif;


//HEader color
//$leftcolor : #3F51B5;
$leftcolor : #cc0038;
$rightcolor : #cc0038;
//$rightcolor : #cc002f;

//....................................//
// 404page - public/FrontEnd/sass/_404.scss.......................//
//....................................//

// main H1
$error-main-h1-font-color : #c62828;
$error-main-h1-font-size : 180px;
$error-main-h1-font-weight : 600;

//....................................//
// BG Colors .........................//
//....................................//
$body : #FAFAFA;
$bgmain : #fff;
$bgsub1 : #fbfbfb;
$bgsub2 : #ededed;
$bgsub3 : #000;
$bgsub4 : #f7f7f7;

//....................................//
// Text Colors (black) ...............//
//....................................//
$color54 : rgba(0, 0, 0, 0.54);
$color38 : rgba(0, 0, 0, 0.38);
$color87 : rgba(0, 0, 0, 0.87);

//....................................//
// Text Colors (White) ...............//
//....................................//
$wcolor54 : rgba(255, 255, 255, 0.54);
$wcolor38 : rgba(255, 255, 255, 0.38);
$wcolor87 : rgba(255, 255, 255, 0.87);

//....................................//
// Highlight Colors ..................//
//....................................//

$hcolor : #e53935;
$wcolor : #fff;

//....................................//
// Border Colors .....................//
//....................................//
$mainborder: #ededed;

//....................................//
// Button List - public/FrontEnd/sass/_buttonslist.scss .......................//
//....................................//

//Red Save Btn
$red-save-btn-bg:#e53935;
$red-save-btn-color:#fff;
$red-save-btn-font-widget:400;
$red-save-btn-font-size:13px;
$red-save-btn-border-radius:2px;
$red-save-btn-border:0 none;

//gray cancel btn
$gray-cancel-btn-bg:#a7a7a7;
$gray-cancel-btn-border-radius:2px;
$gray-cancel-btn-color:#fff;
$gray-cancel-btn-widget:400;
$gray-cancel-btn-border:0 none;
$gray-cancel-btn-font-size:13px;

//....................................//
// card Design - public/FrontEnd/sass/_card.scss .......................//
//....................................//

//Report and Flag icon
$flag-i-font-size: 18px;
$flag-i-line-height: 30px;
$flag-i-font-color: #a2a2a2;

//Report text
$report-text-color : #737373;
$report-text-font-size : 13px;
$report-text-line-height : 13px;
$report-text-line-height-i : 30px;
$report-text-font-size-i : 12px;

//card-footer-votebtn
$vote-btn-bg-color:#fbfbfb;
$vote-btn-border:1px solid #ededed;

//New Variable Rules

//Red
$main-red-bg : #2d2d2d;
$main-red-text : #c62828;
$main-red-border : #c62828;

//black color
$main-black-bg : #000;
$main-black-text : #000;
$main-black-border : #000;

//White color
$main-white-bg : #fff;
$main-white-text : #ffffff;
$main-white-border : #fff;

//Message Colors
$success-bg: #7CB342;
$error-bg: #F4511E;

// Default Brder
$main-border-color: #ededed;

//Main Yellow Color
$main-yellow-bg: #FFAB00;
$main-yellow-text: #FFAB00;
$main-yellow-border: #FFAB00;

// New Colors
$color54 : rgba(0, 0, 0, 0.54);
$color38 : rgba(0, 0, 0, 0.38);
$color87 : rgba(0, 0, 0, 0.87);


//Tet_col
$colorset-test: #F57F17;
$colorset-test2: #FFF9C4;